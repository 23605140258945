// $site-logo-size: $site-tagline-height + $main-nav-height + $main-nav-menu-padding + $theme-search-padding + $sub-nav-height + 3;
$site-logo-size: 200px;
$site-logo-size-height: 150px;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

#sn-site-logo {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 0 - $maxWidth/2;
    width: $site-logo-size;
    height: $site-logo-size-height;
    padding: 8px;
    // background-color: #fff;
    // border: 1px solid rgba(0,0,0,0.1);
    z-index: 1000;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: all $transition-linear;

    .has-mobile-nav & {
        margin-left: 0;
        height: $account-nav-height-mobile;
        width: 130px;
        left: 15px;
    }

    .nav-fixed & {
        // width: $site-logo-size-fixed;
        // height: $site-logo-size-fixed;
        width: 85px;
        height: 45px;
        padding: 0;
    }

    .sn-site-logo-wrap {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }

    .sn-site-logo-background {
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        -webkit-background-size: contain;
    }
}
