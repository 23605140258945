
/* Home Slideshow */
#home-page.user_mode .layoutContainer.custom-slider-previews {
    margin-top: -57px;
    background-color: transparent;
    padding: 0;

    p {
        margin-bottom: 0;
    }

    .column {
        padding: 0;
    }

    .has-mobile-nav & {
        margin-top: -15px;
    }
}

.user_mode .custom-slider-previews {
    .sn-media-slider .slider.top-center-paging,
    .sn-media-slider .slider.top-left-paging,
    .sn-media-slider .slider.top-right-paging {
        margin-top: 0;
    }

    .sn-media-slider {
        overflow: visible;
        padding: 0;

        &:hover {
            .flex-next,
            .flex-prev {
                background: rgba(0,0,0,.7);
            }

            .flex-next {
                right: 0;
            }

            .flex-prev {
                left: 0;
            }

        }

        .slides .slide .slide-overlay {
            padding: 0 60px 5%;
            margin: 0 auto;
        }

        .slider {
            .slide-title {
                font-size: 150%;
                line-height: normal;
                margin: 10px 0;
                font-family: $fontOne;
            }

            .slide-description {
                margin: 5px 0;
                font-family: $fontOne;
                font-size: 100%;
                line-height: normal;

            }

        }

        &:after,
        &:before {
            display: block;
            background: rgba(0, 51, 102, .7);
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 10;
            position: absolute;
            padding-bottom: 0;
        }

        &:before {
            left: 0;
            margin-left: -100%;
        }

        &:after {
            right: 0;
            margin-right: -100%;
        }

    }

    .flex-viewport {
        overflow: visible !important;
    }

    .flex-direction-nav {
        .flex-next,
        .flex-prev {
            position: absolute;
            top: 0;
            height: 100%;
            background: rgba(0,0,0,0);
            overflow: visible;
            transition: all 0.5s ease;
            font-size: 0;
        }

        .flex-next {
            right: 0;

            &:before {
                right: 10px;
            }
        }

        .flex-prev {
            left: 0;

            &:before {
                left: 10px;
            }
        }

        a:before {
            font-size: 45px;
            position: absolute;
            top: 40%;
            transition: 0.3s ease;
        }
    }

    .sn-media-slider .slider.bottom-center-paging,
    .sn-media-slider .slider.bottom-left-paging,
    .sn-media-slider .slider.bottom-right-paging,
    .sn-media-slider .slider.top-center-paging,
    .sn-media-slider .slider.top-left-paging,
    .sn-media-slider .slider.top-right-paging {
        .flex-direction-nav a {
            opacity: 1;
            margin: 0;
            padding: 0;
            transform: none;
        }
    }

}
@media screen and (min-width:1024px) {
    .custom-slider-previews .sn-media-slider .slides .slide {
        width: 970px !important;
    }
}
@media screen and (max-width:1024px) {
    #home-page.user_mode .layoutContainer.custom-slider-previews {
        margin-left: -15px;
        width: calc(100% + 30px);
    }

    .custom-slider-previews {
        overflow: hidden;

        .flex-viewport {
            overflow: hidden !important;
        }
    }
}
@media screen and (max-width:500px) {
    .custom-slider-previews .sn-media-slider .slider {
        .slide-title {
            font-size: 4.3vw;
        }

        .slide-description {
            font-size: 3vw;
        }
    }

}
