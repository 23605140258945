// TABLE OF CONTENTS //
// 1. Home Page Styles
// 2. Header
// 3. Footer
// 4. Random
// 5. Edit Mode Fixes
// 6. Media Queries

// 1. Home Page Styles
#home-page {

    overflow-x: hidden;

    #siteContainer {
        background-color: transparent;
        padding-bottom: 0;
    }

    #topNavPlaceholder {
        border-bottom: 0;
    }

    &.user_mode .layoutContainer {
        padding: 15px;
        background-color: white;
        margin: 20px 0;

        .pageElement {
            margin-bottom: 0;
        }
    }

    &.edit_mode .layoutContainer {
        background-color: white;
    }

    .layoutContainer.layout-25-25-50,
    .layoutContainer.layout-25-50-25,
    .layoutContainer.layout-33-33-33,
    .layoutContainer.layout-33-66,
    .layoutContainer.layout-50-25-25,
    .layoutContainer.layout-50-50,
    .layoutContainer.layout-66-33 {
        background-color: transparent;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .column {
            background-color: white;
            padding: 15px;
            flex: 1;
        }

        .column-2,
        .column-3 {
            margin-left: 2%;
        }

    }

    .layoutContainer.layout-25-25-50 .column-3,
    .layoutContainer.layout-25-50-25 .column-2,
    .layoutContainer.layout-33-66 .column-2,
    .layoutContainer.layout-50-25-25 .column-1,
    .layoutContainer.layout-66-33 .column-1 {
        flex: 2;
    }

}

//reset
.user_mode .layoutContainer {
    margin: 20px 0;
    padding-left: 0;
}

// 2. Header
.theme-search-wrapper .top-social-links {
    flex: 0 0 auto;
    margin-left: 40px;
    align-self: flex-end;
}

// 3. Footer
#home-page.edit_mode .snFooterContainer .layoutContainer,
#home-page.user_mode .snFooterContainer .layoutContainer {
    background-color: transparent;
}

.snFooterContainer {
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    background-color: $link-color;

    .pre-footer-container {
        position: relative;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        max-width: $maxWidth;
        margin: 0 auto;
        background-color: transparent;

        .footer-logo-container {
            flex-basis: 100%;
            border-top: 1px solid white;
            margin-top: 30px;

            img {
                width: 300px;
                display: block;
                margin: -30px auto 20px;
                padding: 0 70px;
                background-color: $link-color;

            }
        }

        .social-links-container {
            flex-basis: 25%;

            .sn-social-media-list .icon-facebook,
            .sn-social-media-list .icon-twitter,
            .sn-social-media-list .icon-youtube,
            .sn-social-media-list .icon-instagram {
                background-color: white;
                color: $link-color;
            }
        }

        .footer-content-container {
            flex-basis: 75%;
        }

        .footer-content,
        .social-links-container {
            color: white;

            h3 {
                color: white;
                text-shadow: none;
                font-size: 21px;
                padding: 8px 0;
                margin: 8px 0;
                border-bottom: 1px solid white;

                span {
                    padding: 0;
                    box-shadow: none;
                    border-bottom: none;
                }
            }

            a:not(.sn-social-media-icon),
            p {
                color: white;
                font-weight: 500;
                font-size: 16px;
            }

            a:hover {
                color: white;
            }
        }

        ul.legal {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            list-style-type: none;
            margin-top: 20px;

            li {
                padding: 0 10px 0 0;
                color: white;
                font-family: $ubuntu;
                font-size: 16px;
            }

            a {
                color: white;
            }
        }
    }
}

#siteFooter {
    padding: 20px;

    #poweredByNGIN {
        display: none;
    }
}

html .user_mode .snFooterContainer {
    .layoutContainer.layout-100,
    .layoutContainer.layout-25-25-50,
    .layoutContainer.layout-25-50-25,
    .layoutContainer.layout-33-33-33,
    .layoutContainer.layout-33-66,
    .layoutContainer.layout-50-25-25,
    .layoutContainer.layout-50-50,
    .layoutContainer.layout-66-33 {
        background-color: transparent !important;

        .column {
            background-color: transparent !important;
        }
    }

}

// 3. Random
.sn-media-slider .slider-pagination {
    height: 20px;
}

.elementMenuOption h3 {
    border-bottom: none;
}

.pageEl div[id^="fb_element_"] {
    height: 515px;
}
// 4. Edit Mode Fixes

.edit_mode .pre-footer-container {
    .instructions {
        display: none;
        position: absolute;
        font-size: 30px;
        padding-top: 50px;
        top: 0;
        left: 0;
        color: white;
        background-color: $link-color-opacity;
        height: 100%;
        width: 100%;
        text-align: center;
        z-index: 40;

        a {
            color: white;
            text-decoration: underline;
        }
    }

    &:hover .instructions {
        display: block;
    }
}
// 5. Media Queries
@media only screen and (max-width: 1024px) {
    .snFooterContainer {
        max-width: 1024px;
    }
}
@media only screen and (max-width:768px) {
    html .site-background {
        background-repeat: repeat;
    }

    #home-page {
        .siteContainer {
            padding-bottom: 0;
        }

        .layoutContainer.layout-25-25-50,
        .layoutContainer.layout-25-50-25,
        .layoutContainer.layout-33-33-33,
        .layoutContainer.layout-33-66,
        .layoutContainer.layout-50-25-25,
        .layoutContainer.layout-50-50,
        .layoutContainer.layout-66-33 {
            .column-2,
            .column-3 {
                margin-left: 0;
                margin-top: 20px;
            }

            .column {
                flex-basis: 100%;
            }
        }
    }

    .snFooterContainer .pre-footer-container {
        .footer-content-container,
        .footer-logo-container,
        .social-links-container {
            flex-basis: 100%;

            img {
                width: 230px;
                padding: 0 35px;
            }
        }

        .sn-social-media-list {
            text-align: center;
        }

        .footer-content,
        .social-links-container {
            .column {
                margin: 15px;
            }

            .text,
            h3 {
                text-align: center !important;
            }
        }

    }

    #siteFooter > ul {
        text-align: center;
    }
}
