/// TABLE OF CONTENTS //
// 1. Full Width Containers
// 2. Full width Button
// 3. Custom CTA 1
// 4. Custom CTA 2
// 5. Sponsor
// 6. Testimonials
// 7. Edit Mode Fixes
// 8. Media Queries

//1. Full Width Containers ________________________________

#home-page.user_mode .layoutContainer.full-width {
    box-sizing: border-box;
    padding: 0;
    width: 100vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: calc(-1*(100vw - 100%)/2);
    padding: 30px calc((100vw - 100%)/2);
    background-color: white;
}

// 2. Full Width Button ____________________________
.full-width-button .linkElement h4 {
    display: block;
}

// 3. Custom CTA #1 - Mutlicolumn and ColorOverlay ____________________________
#home-page .layoutContainer.multiColumn {
    position: relative;
    z-index: 1;
    display: block;
    padding: 15px;
    background-color: white;

}

.layoutContainer.multiColumn {
    .column {
        flex: none;
    }

    .col-md-3,
    .col-sm-12 {
        padding: 0 !important;
        margin: 0 !important;
    }

    .pageEl {
        border-right: 1px solid white;
    }

    .heroPhotoElement img {
        border: none;
    }
}

#home-page .colorOverlay .sn-call-to-action.sn-variation-slide-up {
    margin: 0;
}

.colorOverlay {
    .pageElement {
        margin-bottom: 0;
    }

    .sn-call-to-action.sn-variation-slide-up {
        padding: 0;
        border: 0;
        margin: 15px 0;

        &:hover .sn-call-to-action-overlay {
            height: 100%;
        }

        .sn-call-to-action-overlay {
            min-height: 72px;
            padding: 5px;
            background-color: rgba(0,0,0,.4);
            height: 75px;
        }

        .sn-call-to-action-overlay-text {
            vertical-align: middle;
        }

        img {
            display: none;
        }

        .imageOverlay {
            background: rgba(102, 153, 204, .5);
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        h4.sn-call-to-action-title {
            max-width: 100%;
            margin: auto;
            line-height: 25px;
            word-wrap: initial;
            font-size: 19px;
            font-weight: 400;
            padding: 10px 0;
        }

        hr {
            display: none;
            background-color: white;
            width: 24%;
            margin: 0 auto;
            height: 1px;
        }

        &:hover hr {
            display: block;
        }
    }
}

// 3. Custom CTA #2  __________________________________________

#home-page .layoutContainer.custom-cta {
    display: flex;

    .pageEl .pageElement {
        margin: 0;
    }

    .column {
        background-color: $link-color;
        padding: 15px 0;
        margin: -40px 0 0;
        height: 285px;

        &:first-of-type {
            padding-left: 15px;
        }

        &:last-of-type {
            padding-right: 15px;
        }
    }

    .video {
        height: 255px;
    }

    .sn-call-to-action {
        padding: 0;
        height: 255px !important;
        max-height: 255px !important;

        &:hover .sn-call-to-action-overlay {
            height: 100%;
        }

        hr {
            display: none;
        }

        img {
            height: 255px !important;
            object-fit: cover;
        }

        .sn-call-to-action-overlay {
            background-color: rgba(0,0,0,.5);
            height: 80px;

            .sn-call-to-action-overlay-text {
                vertical-align: bottom;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .sn-call-to-action-overlay-inner {
                position: absolute;
                bottom: 0;
                left: 0;

                .sn-call-to-action-title {
                    border-top: 2px solid white;
                    margin: 20px auto;
                    width: 80%;
                    padding-top: 10px !important;
                    padding-bottom: 0 !important;
                }

                .sn-call-to-action-subtitle {
                    width: 100%;
                    text-align: center;
                    order: -1;

                    a {
                        padding: 5px 0;
                    }
                }
            }
        }

    }
}

// Edit mode fixes
#home-page.user_mode .layoutContainer.custom-cta {
    // margin-bottom: -40px;
    // margin-top: 60px;
    // padding-top: 0;

    .column {
        margin-top: 0;
    }
}

#home-page.edit_mode .layoutContainer.custom-cta .column {
    padding: 0;
    margin-top: 0;
    height: auto;
}

// 4. Sponsor ________________________________
.sponsors {
    text-align: center;

    .sn-media-slider {
        .slider {
            background: transparent;
        }

        .flexslider {
            height: 200px !important;
        }

        &:hover {
            .flex-direction-nav .flex-next {
                right: 0;
            }

            .flex-direction-nav .flex-prev {
                left: 0;
            }

        }

    }

    .slides .slide .media-wrapper img {
        //OVERRIDE INLINE STYLES
        position: relative !important;
        max-width: 80% !important;
        width: auto !important;
        left: auto !important;
        margin: 0 auto;
        max-height: 100% !important;
        height: auto !important;
        top: auto !important;
    }

    h2 {
        display: inline;
        color: $accent-color;
        font-size: 20px;
        background-color: white;
        padding: 0 20px;
    }

    p {
        border-top: 1px solid $light-gray;
        margin-top: -14px;
        padding-top: 30px;
    }

    .flex-direction-nav {
        .flex-prev {
            left: 0;
        }

        .flex-next {
            right: 0;
        }

        a {
            opacity: 1;

            &:before {
                text-shadow: none;
                font-size: 30px;
                font-weight: 100;
                color: $light-gray;
            }

        }

        a:hover:before {
            color: $accent-color;
        }

    }
}

// 5. Testimonial Slider ________________________________

.testimonials {
    .column {
        flex: none;
    }

    .mediaSlider {
        text-align: center;

        h2 {
            display: inline;
            color: $accent-color;
            font-size: 20px;
            background-color: white;
            padding: 0 20px;
        }

        p {
            border-top: 1px solid $light-gray;
            margin-top: -14px;
            padding-top: 30px;

            &:empty {
                padding-top: 10px;
            }
        }
    }

    .sn-media-slider {
        .flexslider {
            height: 340px !important;
        }

        .slides .slide {
            display: flex !important;
            flex-direction: column;

            .media-wrapper {
                position: relative;
                height: 100px;
                order: -1;
                overflow: visible;

                img {
                    position: relative !important;
                    width: 90px !important;
                    height: 90px !important;
                    left: auto !important;
                    margin: 0 auto;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }

        .slider {
            background-color: white;

            .slide-overlay {
                padding: 0 7%;
                margin-top: 0;
                position: relative;

            }

            .slide-text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 20px;
            }

            .slide-title {
                font-size: 18px;
                font-family: $fontTwo;
                color: $dark-gray;
                line-height: 30px;
                text-shadow: none;
                margin: 10px 0;
                order: 1;
            }

            .slide-description {
                font-family: $fontTwo;
                font-size: 21px;
                font-weight: 100;
                color: #999999;
                text-shadow: none;
                margin: 0;
                line-height: 33px;
                display: block;
            }
        }

        .flex-direction-nav {
            .flex-prev {
                left: 0;
            }

            .flex-next {
                right: 0;
            }

            a {
                opacity: 1;

                &:before {
                    text-shadow: none;
                    font-size: 30px;
                    font-weight: 100;
                    color: $light-gray;
                }

            }

            a:hover:before {
                color: $accent-color;
            }

        }

    }

}

// 6. Edit Mode Fixes ____________________________________
.edit_mode .colorOverlay .sn-variation-slide-up img {
    display: block;
}

.edit_mode .layoutContainer.custom-cta .column {
    height: auto;
    margin-top: 0;

    .sn-call-to-action.sn-variation-slide-up img {
        height: auto;
    }

    .video {
        height: auto;
    }
}

// 7. Media Queries ___________________________________________
@media only screen and (max-width:1024px) {
    .layoutContainer.multiColumn {
        display: flex !important;
        flex-wrap: wrap;

        .column {
            flex: 1 1 50% !important;
            border-bottom: 1px solid white;
        }
    }

    .colorOverlay .sn-call-to-action.sn-variation-slide-up {
        margin: 0;

        .sn-call-to-action-overlay {
            height: 100%;

            hr {
                display: block;
                margin: 10px auto;
            }
        }

        .sn-call-to-action-subtitle {
            font-size: 17px;
            opacity: 1;
            padding:5px 0;
        }
    }
}
@media only screen and (min-width:768px) and (max-width:1024px) {
    //Full Width
    #home-page.user_mode .layoutContainer.full-width {
        width: calc(100% + 30px);
        margin-left: -15px;
    }

    //Custom CTA 1
    #home-page.user_mode .full-width.layoutContainer.custom-cta {
        background-color: $link-color;
        margin-bottom: 0;
        margin-top: inherit;
        padding: 0;
        flex-wrap: wrap;

        .column {
            flex: 1 1 auto;
            height: auto;
            padding-bottom: 0;
            margin: 0;

            &:nth-of-type(2) {
                padding-right: 15px;
            }

            &:last-of-type {
                flex-basis: 100%;
                padding: 0 15px 15px;
                margin: 0;
            }

            .video {
                height: auto;
            }
        }

    }
}
@media only screen and (max-width:768px) {
    //Full Width
    #home-page.user_mode .layoutContainer.full-width {
        margin-top: 0;
        margin-bottom: 0;
        padding: 30px 0;
    }

    // Custom CTA 1
    .layoutContainer.multiColumn .column {
        flex-basis: 100% !important;
    }

    .colorOverlay .sn-call-to-action h4.sn-call-to-action-title {
        max-width: 50%;
        font-size: 25px;
    }

    // Custom CTA 2
    #home-page.user_mode .layoutContainer.custom-cta {
        background-color: $link-color;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;

        .column {
            height: auto;
            padding: 5px 10px;
            margin: 0;

            &:first-of-type {
                padding-top: 10px;
            }

            &:last-of-type {
                padding-bottom: 10px;
            }

            .video {
                height: auto;
            }

            .sn-call-to-action.sn-variation-slide-up img {
                height: 255px;
                object-fit: cover;
            }
        }
    }

    // Testimonials

    .testimonials .sn-media-slider {
        .flexslider {
            height: 390px !important;
        }

        .slider .slide-description {
            font-size: 19px;
            line-height: 35px;
        }
    }

}
@media only screen and (max-width:500px) {
    .sponsors .sn-media-slider .flexslider {
        height: 140px !important;
    }

    .testimonials .sn-media-slider {
        .flexslider {
            height: 390px !important;
        }

        .slider .slide-description {
            font-size: 16px;
            line-height: 25px;
        }
    }
}
